<template>
  <div>
    <v-container>
      <div class="pa-md-4 mx-lg-auto">
        <div class="text-h3 my-12 text-center">应用授权</div>
      </div>

      <!-- 视图选择 view 0->加载中 1->授权 2->预授权成功 3->初始化失败 4->取消授权 5->正常成功-->
      <v-container v-show="view == 0">
        <div class="mt-6 mb-2 d-flex justify-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </div>
        <div class="text-h5 text-center mb-3">加载中</div>
      </v-container>

      <!-- 获取用户授权页面 -->
      <v-container v-show="view == 1">
        <v-card class="mx-auto" max-width="400" tile>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                您正在登录
                <b>
                  {{ service.name }}
                </b>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-for="(scope, index) in service.scopes" :key="index">
            <v-list-item-icon :key="index">
              <v-icon>mdi-{{ icon[scope] }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ scope_name[scope] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-spacer></v-spacer>

        <div class="mt-6 mb-2">
          <div class="text--secondary text-center">
            您的以上信息将被授权提供给该站点。
          </div>
        </div>

        <div class="d-flex justify-center mt-6">
          <v-btn class="mx-4" depressed @click="cancelSSO"> 取消 </v-btn>
          <v-btn class="mx-4" depressed color="blue lighten-3" @click="confirm">
            授权登录
          </v-btn>
        </div>
      </v-container>

      <!-- 授权成功页面（预授权） -->
      <v-container v-show="view == 2">
        <div class="mt-6 mb-2">
          <div class="text-h5 text-center mb-3">
            <v-icon>mdi-check-circle</v-icon>您已完成本次登录授权
          </div>
          <div class="text--secondary text-center">您可以关闭此窗口了</div>
        </div>
      </v-container>

      <!-- 初始化失败页面 -->
      <v-container v-show="view == 3">
        <div class="mt-6 mb-2">
          <div class="text-h5 text-center">初始化失败</div>
          <div class="text--secondary text-center">
            请从正确的入口进入本页面
          </div>
        </div>
        <v-row justify="center" class="mt-4">
          <v-btn to="/" class="mx-auto" depressed>返回首页</v-btn>
        </v-row>
      </v-container>

      <!-- 取消成功页面 -->
      <v-container v-show="view == 4">
        <div class="mt-6 mb-2">
          <div class="text--secondary text-center">您已取消授权</div>
          <div class="text--secondary text-center">请关闭当前窗口</div>
        </div>
      </v-container>

      <!-- 授权成功，等待跳转页面 -->
      <v-container v-show="view == 5">
        <div class="mt-6 mb-2">
          <div class="text-h5 text-center mb-3">
            <v-icon>mdi-check-circle</v-icon>您已完成本次登录授权
          </div>
          <div class="text--secondary text-center">等待跳转</div>
        </div>
      </v-container>
    </v-container>

    <!-- 提示框 -->
    <v-dialog v-model="dialog.alert" max-width="450px">
      <v-card>
        <v-card-title>{{ dialog.alert_type }}</v-card-title>
        <v-card-text>
          {{ dialog.alert_text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog.alert = !dialog.alert">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "sso",
  data: function () {
    return {
      name: "",
      password: "",
      disable_input: false,
      loader: null,
      loading: false,
      dialog: {
        alert_text: "",
        alert_type: "info",
        alert: false,
      },
      service: {
        client_id: "",
        name: "服务名称",
        scopes: [],
        redirect_url: "#",
        confirm_url: "",
        access_token: "",
        confirmed: false,
        is_pre_sign: false,
      },
      scope_name: {
        userid: "用户ID",
        username: "用户名",
        email: "邮箱地址",
        avatar: "头像",
        user_group: "用户群组",
      },
      icon: {
        userid: "key",
        username: "clipboard-account",
        email: "email",
        avatar: "account-circle",
        user_group: "account-group",
      },
      view: 0,
    };
  },
  mounted: function () {
    var _this = this;
    document.onkeydown = function () {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.submit();
      }
    };
    console.log(
      this.$route.query
    );
    this.axios
      .get("/api/sso/sinfo", {
        timeout: 5000,
        params: this.$route.query,
      })
      .then((res) => {
        if (res.data.status < 0) {
          //失败
          if (res.data.status == -1001) {
            //登录状态失效
            //this.$router.push("/");
            this.$router.push({ path: "/login", query: { callback: this.$route.fullPath } });
          }
          this.view = 3;
          this.error(res.data.msg);
          return;
        }

        //成功
        _this.service = res.data.data;
        if (_this.service.confirmed == true) {
          this.view = 5;
          //this.redirect_dialog("您已完成本次授权,请返回目标站点进行操作");
          setTimeout(() => {
            window.location.href = _this.service.redirect_url;
          }, 1500);
          return;
        }
        this.view = 1;
      })
      .catch((err) => {
        _this.error("信息查询失败(" + err + ")");
        this.view = 3;
      });
  },
  methods: {
    cancelSSO() {
      this.axios({
        url: "/api/sso/cancel",
        data: this.$route.query,
        method: "post",
      })
        .then((res) => {
          if (res.data.status == 0) {
            this.view = 4;
          } else {
            this.error(this.parseError(res));
          }
        })
        .catch((err) => {
          this.error("服务器连接失败:" + err);
        });
    },
    confirm() {
      this.axios({
        method: "post",
        url: "/api/sso/confirm",
        data: {
          access_token: this.service.access_token,
          client_id: this.$route.query.client_id,
          state: this.$route.query.state
        }
      })
        .then((res) => {
          if (res.data.status == 0) {
            //根据是否未预授权来判断展示哪个页面
            if (this.service.is_pre_sign == true) {
              this.view = 2;
            } else {
              this.view = 5;
              window.location.href = this.service.redirect_url;
            }
          } else {
            this.error(this.parseError(res));
          }
        })
        .catch((err) => {
          this.error("服务器连接失败:" + err);
        });
    },
    success: function (text) {
      this.dialog.alert = !this.dialog.alert;
      this.dialog.alert_text = text;
      this.dialog.alert_type = "成功";
    },
    error: function (err) {
      this.dialog.alert = !this.dialog.alert;
      this.dialog.alert_text = err;
      this.dialog.alert_type = "失败";
    },
    redirect_dialog: function (text) {
      this.dialog.alert = !this.dialog.alert;
      this.dialog.alert_text = text;
      this.dialog.alert_type = "即将跳转";
    },
    show_loading: function () {
      this.loader = "loading";
    },
  },
};
</script>

<style></style>
